import React from "react";

function Bullseye() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="160"
            height="90"
            fill="none"
            viewBox="0 0 160 90"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="160"
                height="90"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H159.911V90H0z"></path>
            </mask>
            <g clipPath="url(#clip0_0_247)">
                <path
                    fill="#95C3EA"
                    d="M75.057 62.182a11.807 11.807 0 01-8.405-3.476c-4.634-4.637-4.634-12.178 0-16.816a11.82 11.82 0 018.412-3.479c1.47 0 2.91.267 4.285.799a1.172 1.172 0 01.408 1.926l-6.928 6.927a3.159 3.159 0 002.232 5.389c.843 0 1.634-.33 2.231-.926L84.22 45.6a1.18 1.18 0 011.93.408 11.9 11.9 0 01-2.681 12.7 11.809 11.809 0 01-8.408 3.475h-.004zm.004-21.42c-2.55 0-4.947.991-6.746 2.79-3.72 3.72-3.72 9.773 0 13.492a9.48 9.48 0 006.742 2.787c2.55 0 4.946-.991 6.746-2.787a9.595 9.595 0 002.708-8.02l-.045-.344-5.511 5.512a5.483 5.483 0 01-3.894 1.614 5.463 5.463 0 01-3.895-1.614 5.515 5.515 0 010-7.788l5.509-5.508-.343-.044a9.82 9.82 0 00-1.268-.086l-.003-.004z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M75.057 86.786c-9.745 0-18.908-3.795-25.798-10.688-6.89-6.893-10.688-16.051-10.688-25.8 0-9.748 3.795-18.91 10.688-25.8 6.894-6.89 16.053-10.687 25.798-10.687 6.612 0 13.067 1.772 18.678 5.128v2.772c-5.536-3.633-11.987-5.549-18.678-5.549-9.118 0-17.69 3.551-24.139 9.999-6.447 6.447-9.998 15.02-9.998 24.137 0 9.118 3.55 17.69 9.998 24.138 6.448 6.447 15.021 9.998 24.14 9.998 9.117 0 17.69-3.55 24.138-9.998 6.447-6.448 9.999-15.02 9.999-24.138 0-6.694-1.92-13.145-5.55-18.68h2.773c3.356 5.61 5.128 12.065 5.128 18.68 0 9.745-3.794 18.91-10.688 25.8-6.893 6.89-16.052 10.688-25.797 10.688h-.004z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M75.057 74.473c-6.468 0-12.542-2.512-17.101-7.07a24.029 24.029 0 01-7.085-17.105 24.029 24.029 0 017.085-17.104c4.559-4.555 10.63-7.064 17.094-7.064 4.902 0 9.609 1.446 13.626 4.185l-1.69 1.69a21.771 21.771 0 00-11.925-3.53c-5.838 0-11.322 2.265-15.443 6.381a21.695 21.695 0 00-6.396 15.442 21.696 21.696 0 006.396 15.442c4.117 4.116 9.602 6.382 15.44 6.382 5.837 0 11.321-2.266 15.441-6.382 7.274-7.277 8.467-18.763 2.852-27.367l1.69-1.69a24.166 24.166 0 01-2.882 30.723c-4.563 4.558-10.637 7.07-17.102 7.07v-.003z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M75.057 51.474a1.175 1.175 0 01-.83-2.005l20.674-20.673v-10.54c0-.315.123-.61.342-.83l13.064-13.065c.223-.223.517-.343.833-.343a1.172 1.172 0 011.176 1.176v9.854h9.851a1.179 1.179 0 01.833 2.009l-13.063 13.06a1.165 1.165 0 01-.83.342h-10.54L75.897 51.13a1.164 1.164 0 01-.83.343h-.01zm22.192-32.73v9.364h9.364l10.712-10.709h-8.189a1.177 1.177 0 01-1.175-1.175V8.032L97.249 18.743z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.3"
                    d="M111.962 71.156a1.038 1.038 0 00.076 1.462l.333.302-.302.333a1.038 1.038 0 00.073 1.462c.205.186.469.281.747.267.275-.015.53-.136.715-.339l.302-.333.333.301a1.037 1.037 0 001.462-.072 1.037 1.037 0 00-.075-1.463l-.333-.301.301-.333a1.034 1.034 0 00-1.538-1.387l-.301.333-.333-.302a1.034 1.034 0 00-1.46.076v-.006zm2.033.837l.499-.55a.45.45 0 01.666.602l-.498.55a.295.295 0 00.02.415l.55.498c.185.165.2.452.032.634a.447.447 0 01-.634.032l-.551-.498a.297.297 0 00-.211-.076.287.287 0 00-.203.096l-.498.55a.448.448 0 11-.666-.602l.498-.55a.297.297 0 00-.02-.415l-.55-.498a.446.446 0 01-.032-.634.45.45 0 01.634-.032l.55.498a.298.298 0 00.212.075.242.242 0 00.11-.028.352.352 0 00.089-.067h.003z"
                ></path>
            </g>
            <path
                fill="#282828"
                fillOpacity="0.1"
                d="M41.558 14.32a.965.965 0 001.028-.895l.03-.417.416.029a.959.959 0 001.026-.895.959.959 0 00-.895-1.025l-.417-.03.029-.416a.959.959 0 00-.895-1.026.965.965 0 00-1.029.895l-.029.417-.417-.029a.965.965 0 00-.793 1.593.95.95 0 00.66.327l.417.03-.029.416a.962.962 0 00.895 1.026h.003zm-.556-1.97l-.69-.046a.415.415 0 01-.387-.446.416.416 0 01.446-.388l.689.046a.27.27 0 00.29-.252l.046-.689a.418.418 0 11.834.058l-.046.69a.272.272 0 00.252.289l.689.046a.415.415 0 01.388.446.415.415 0 01-.446.388l-.69-.046a.272.272 0 00-.289.252l-.046.69a.416.416 0 01-.446.387.415.415 0 01-.388-.446l.046-.689a.273.273 0 00-.067-.197.25.25 0 00-.185-.093zM23.303 69.053a1.288 1.288 0 001.298 1.271l.559-.006.006.56a1.282 1.282 0 001.297 1.27 1.27 1.27 0 00.904-.384c.24-.247.37-.568.368-.913l-.006-.559.559-.005a1.282 1.282 0 001.271-1.298 1.288 1.288 0 00-1.297-1.272l-.56.006-.005-.559a1.29 1.29 0 00-1.297-1.271 1.27 1.27 0 00-.904.385c-.24.246-.37.568-.368.912l.006.56-.56.005a1.284 1.284 0 00-1.268 1.298h-.003zm2.558-.947l-.009-.921a.557.557 0 01.553-.565.56.56 0 01.565.553l.009.921c0 .2.165.362.368.36l.92-.01a.561.561 0 01.406.95.557.557 0 01-.394.168l-.92.01a.355.355 0 00-.256.11.373.373 0 00-.104.257l.009.92a.557.557 0 01-.95.406.557.557 0 01-.168-.394l-.009-.92a.355.355 0 00-.11-.255.374.374 0 00-.258-.105l-.92.01a.56.56 0 01-.012-1.119l.92-.009c.096 0 .189-.04.256-.11a.354.354 0 00.104-.257z"
            ></path>
            <path
                fill="#282828"
                d="M39.201 23.526a.83.83 0 00.113-1.167l-.229-.278.278-.229a.831.831 0 00.299-.559.82.82 0 00-.186-.605.82.82 0 00-.559-.298.82.82 0 00-.605.185l-.278.229-.229-.278a.832.832 0 00-.559-.299.83.83 0 00-.718 1.353l.229.278-.278.229a.83.83 0 00.446 1.465.82.82 0 00.605-.185l.278-.229.229.278a.828.828 0 001.167.113l-.003-.003zm-1.512-.9l-.457.379a.363.363 0 01-.264.081.359.359 0 01-.194-.637l.458-.38a.237.237 0 00.032-.33l-.38-.457a.36.36 0 01.313-.588.352.352 0 01.243.13l.38.458a.23.23 0 00.159.084.228.228 0 00.17-.052l.458-.38a.364.364 0 01.264-.081.363.363 0 01.324.394.352.352 0 01-.13.243l-.458.38a.23.23 0 00-.084.159.229.229 0 00.052.17l.38.458a.359.359 0 01-.05.507.363.363 0 01-.263.081.352.352 0 01-.243-.13l-.38-.458a.23.23 0 00-.159-.084.252.252 0 00-.093.01.247.247 0 00-.08.043h.002z"
            ></path>
            <path
                fill="#95C3EA"
                d="M122.945 13.745a.978.978 0 001.356-.263l.237-.35.351.237a.977.977 0 001.095-1.616l-.351-.238.238-.35a.973.973 0 00-.261-1.355.979.979 0 00-1.356.263l-.237.35-.35-.237a.978.978 0 00-1.095 1.619l.35.237-.237.35a.973.973 0 00.263 1.356l-.003-.003zm.513-2.01l-.579-.39a.43.43 0 01-.116-.59.42.42 0 01.588-.114l.579.39a.275.275 0 00.382-.075l.391-.579a.422.422 0 01.588-.113.429.429 0 01.116.59l-.391.58a.273.273 0 00.073.382l.579.391c.092.064.156.16.179.273.021.11 0 .223-.066.318a.43.43 0 01-.591.116l-.579-.39a.29.29 0 00-.206-.044.278.278 0 00-.177.116l-.391.579a.42.42 0 01-.588.113.429.429 0 01-.115-.59l.391-.58a.289.289 0 00.043-.206.251.251 0 00-.041-.101.303.303 0 00-.075-.076h.006zM92.206 6.518a.633.633 0 00-.869.223l-.142.237-.237-.142a.634.634 0 00-.649 1.09l.237.141-.141.238a.634.634 0 101.088.645l.142-.237.238.142a.633.633 0 00.938-.704.624.624 0 00-.29-.388l-.237-.142.142-.238a.634.634 0 00-.223-.868l.003.003zm-.258 1.323l.391.232a.275.275 0 01.099.377.277.277 0 01-.38.095l-.39-.232a.18.18 0 00-.247.064l-.232.39a.277.277 0 01-.477-.28l.231-.391a.186.186 0 00.02-.136.175.175 0 00-.08-.11l-.392-.232a.275.275 0 01.278-.475l.391.232c.041.023.09.032.137.02a.175.175 0 00.11-.081l.231-.391a.277.277 0 01.478.28l-.231.392a.186.186 0 00-.02.136.188.188 0 00.083.11z"
            ></path>
            <path
                fill="#282828"
                d="M119.986 33.802c.423-.446.4-1.152-.046-1.575l-.354-.333.333-.354a1.115 1.115 0 10-1.622-1.529l-.333.353-.353-.333a1.112 1.112 0 00-1.576.047 1.118 1.118 0 00.047 1.575l.353.333-.333.354a1.116 1.116 0 001.622 1.529l.333-.354.353.334a1.114 1.114 0 001.576-.047zm-2.169-.947l-.548.582a.483.483 0 01-.686.018.487.487 0 01-.02-.687l.547-.582a.315.315 0 00-.015-.446l-.582-.547a.488.488 0 01-.02-.687.483.483 0 01.686-.017l.583.547c.06.058.142.087.226.087a.305.305 0 00.22-.098l.547-.582a.485.485 0 01.704.668l-.548.583a.324.324 0 00-.086.226c0 .084.037.162.098.22l.582.547c.194.183.203.49.02.687a.486.486 0 01-.686.017l-.582-.547a.325.325 0 00-.226-.087.293.293 0 00-.122.029.344.344 0 00-.098.07h.006z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.2"
                d="M127.305 42.436a1.13 1.13 0 00-1.529.455l-.235.431-.431-.235a1.11 1.11 0 00-.858-.09 1.112 1.112 0 00-.669.545c-.145.264-.176.57-.089.858.086.29.278.527.544.672l.432.234-.235.432c-.145.263-.177.57-.09.857a1.13 1.13 0 002.074.217l.234-.431.432.234a1.13 1.13 0 001.075-1.984l-.432-.234.235-.432a1.127 1.127 0 00-.455-1.53h-.003zm-.365 2.369l.713.385a.491.491 0 11-.47.863l-.712-.385a.32.32 0 00-.432.127l-.385.713a.491.491 0 01-.863-.47l.385-.712a.323.323 0 00.026-.243.31.31 0 00-.153-.188l-.713-.385a.485.485 0 01-.237-.293.484.484 0 01.04-.374.488.488 0 01.667-.197l.712.386c.075.04.162.049.243.026a.305.305 0 00.189-.154l.385-.712a.491.491 0 01.863.469l-.385.712a.318.318 0 00.127.432z"
            ></path>
            <path
                fill="#95C3EA"
                d="M23.652 38.264a1.068 1.068 0 00-.93-1.19l-.46-.058.057-.46a1.069 1.069 0 10-2.12-.261l-.058.46-.46-.058a1.07 1.07 0 00-1.19.93 1.068 1.068 0 00.93 1.19l.46.059-.058.46a1.068 1.068 0 002.12.26l.058-.46.46.058a1.068 1.068 0 001.19-.93zm-2.213.502l-.093.761a.468.468 0 01-.174.31.47.47 0 01-.341.096.468.468 0 01-.406-.519l.093-.761a.303.303 0 00-.264-.337l-.761-.092a.468.468 0 01-.232-.828.47.47 0 01.342-.096l.761.093a.31.31 0 00.223-.061.294.294 0 00.113-.2l.093-.762a.475.475 0 01.174-.31.468.468 0 01.652.078.47.47 0 01.095.342l-.093.762a.31.31 0 00.061.223c.05.064.122.104.2.113l.762.093a.468.468 0 01.232.828.47.47 0 01-.342.096l-.762-.093a.31.31 0 00-.301.15.312.312 0 00-.038.11l.006.004z"
            ></path>
            <defs>
                <clipPath id="clip0_0_247">
                    <path
                        fill="#fff"
                        d="M0 0H82.768V82.768H0z"
                        transform="translate(38.572 4.018)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Bullseye;
