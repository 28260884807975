import React from "react";

function Notebook() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="160"
            height="90"
            fill="none"
            viewBox="0 0 160 90"
        >
            <mask
                id="mask0_0_219"
                style={{ maskType: "alpha" }}
                width="160"
                height="90"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H159.911V90H0z"></path>
            </mask>
            <g mask="url(#mask0_0_219)">
                <path
                    fill="#95C3EA"
                    d="M77.455 34.32c9.022 0 16.358-7.338 16.358-16.357 0-.742-.05-1.49-.15-2.222a.87.87 0 00-1.724.235c.09.654.133 1.323.133 1.987 0 8.063-6.56 14.62-14.62 14.62-8.06 0-14.62-6.56-14.62-14.62 0-8.06 6.56-14.62 14.62-14.62 3.681 0 7.197 1.372 9.905 3.863a.872.872 0 001.228-.05.872.872 0 00-.05-1.227 16.308 16.308 0 00-11.08-4.324c-9.022 0-16.358 7.339-16.358 16.358 0 9.018 7.339 16.358 16.358 16.358z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M70.82 16.216a.869.869 0 00-1.228 1.228l7.425 7.426c.171.171.392.255.615.255a.857.857 0 00.614-.255l13.528-13.528a.869.869 0 00-1.228-1.228L77.63 23.028l-6.811-6.812zM90.277 54.603a.868.868 0 00-.87-.869h-30.98a.868.868 0 100 1.738h30.98c.481 0 .87-.388.87-.869zM83.9 61.264a.868.868 0 00-.87-.869h-24.6a.868.868 0 100 1.738h24.6c.481 0 .87-.388.87-.869zM68.41 67.636a.868.868 0 00-.869-.869H58.43a.868.868 0 100 1.738h9.111c.48 0 .869-.388.869-.869zM118.906 44.394l1.613-1.613a1.778 1.778 0 000-2.512l-1.7-1.7a1.762 1.762 0 00-1.254-.518c-.472 0-.921.185-1.254.518l-1.613 1.613-.895-.894a2.699 2.699 0 00-1.92-.797 2.7 2.7 0 00-1.921.797L84.606 64.644a11.155 11.155 0 00-3.192 6.662l-.7 6.476a.865.865 0 00.862.961c.04 0 .078 0 .119-.008l6.392-.884a11.166 11.166 0 006.334-3.151l.336-.336 21.34-21.34.755.756c.261.261.403.606.403.977 0 .37-.145.715-.403.976l-8.992 8.992a.869.869 0 001.228 1.228l8.992-8.992a3.1 3.1 0 00.913-2.204 3.1 3.1 0 00-.913-2.205l-.755-.755 2.571-2.572a2.696 2.696 0 00.797-1.92c0-.727-.281-1.408-.797-1.92l-.993-.994.003.003zm-1.367-4.597s.04-.011.052 0l1.7 1.7a.036.036 0 010 .053l-1.613 1.613-1.752-1.752 1.616-1.614h-.003zm-29.69 36.337l-5.288.732.58-5.37a9.413 9.413 0 012.305-5.21l7.454 7.455a9.402 9.402 0 01-5.053 2.39l.003.003zm6.294-3.61l-7.481-7.48 20.728-20.726 7.481 7.481-20.725 20.726h-.003zM118.668 48l-2.571 2.572-7.481-7.48 2.571-2.572a.975.975 0 01.693-.287c.26 0 .506.101.692.287l6.096 6.099a.97.97 0 01.287.692.97.97 0 01-.287.692V48zM95.785 40.701H58.43a.868.868 0 100 1.738h37.358a.868.868 0 100-1.738h-.003zM96.654 48.231a.868.868 0 00-.869-.869H58.43a.868.868 0 100 1.738h37.358c.481 0 .87-.388.87-.869h-.004zM96.066 11.449h5.315c2.664 0 4.918 2.259 4.918 4.926v19.741a.867.867 0 101.737 0v-19.74c0-3.624-3.032-6.665-6.655-6.665h-5.315a.868.868 0 100 1.738z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M107.17 69.264a.867.867 0 00-.868.869v7.727c0 2.664-2.254 4.834-4.921 4.834H53.529c-2.665 0-4.863-2.17-4.863-4.834V16.375c0-2.664 2.199-4.926 4.863-4.926h4.9a.868.868 0 100-1.738h-4.9c-3.623 0-6.6 3.038-6.6 6.664v61.488c0 3.623 2.977 6.571 6.6 6.571h47.852c3.623 0 6.658-2.948 6.658-6.571v-7.727a.868.868 0 00-.869-.87v-.002z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M61.163 78.32c1.715-1.277 2.512-.874 3.612-.318.872.44 1.955.985 3.415.533 1.23-.382 2.117-1.4 2.83-2.216.315-.362.781-.898 1.007-.993.073.052.281.24.597.866.654 1.303 1.55 2.149 2.662 2.51.422.137.83.189 1.207.189 1.04 0 1.816-.391 1.86-.414a.867.867 0 00.376-1.162.873.873 0 00-1.164-.388c-.353.174-2.17.913-3.389-1.517-.362-.721-.958-1.697-1.931-1.82-1.034-.127-1.764.705-2.535 1.585-.596.684-1.274 1.46-2.036 1.697-.753.235-1.268.003-2.117-.422-1.208-.609-2.858-1.44-5.433.477a.869.869 0 101.04 1.393z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.3"
                    d="M124.791 51.64a1.038 1.038 0 00.075 1.463l.333.301-.301.333a1.039 1.039 0 00.073 1.463 1.04 1.04 0 001.462-.072l.301-.334.333.301a1.04 1.04 0 001.463-.072 1.038 1.038 0 00-.075-1.463l-.333-.3.301-.334a1.035 1.035 0 00-1.538-1.387l-.301.333-.333-.301a1.034 1.034 0 00-1.46.075v-.006zm2.033.837l.498-.55a.45.45 0 01.666.602l-.498.55a.296.296 0 00.021.415l.55.498c.185.165.2.452.032.634a.45.45 0 01-.635.032l-.55-.498a.297.297 0 00-.211-.075.285.285 0 00-.203.095l-.498.55a.444.444 0 01-.634.032.444.444 0 01-.032-.634l.498-.55a.297.297 0 00-.02-.414l-.551-.499a.447.447 0 01-.032-.634.446.446 0 01.635-.032l.55.498a.296.296 0 00.211.076.235.235 0 00.11-.03.343.343 0 00.09-.066h.003z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M118.793 73.66a.627.627 0 00-.884-.037l-.199.185-.186-.2a.623.623 0 00-.883-.038.621.621 0 00-.038.883l.186.2-.2.186a.621.621 0 00-.038.883c.235.255.631.27.883.038l.2-.185.186.2a.626.626 0 00.921-.846l-.186-.2.2-.185a.625.625 0 00.035-.884h.003zm-.617 1.18l.304.33a.269.269 0 01.072.196.27.27 0 01-.086.188.272.272 0 01-.386-.017l-.304-.33a.178.178 0 00-.249-.012l-.33.304a.271.271 0 01-.385-.017.27.27 0 01-.073-.197.275.275 0 01.087-.188l.33-.304a.179.179 0 00.058-.122.186.186 0 00-.046-.127l-.304-.33a.274.274 0 01.014-.386.275.275 0 01.386.014l.304.33a.179.179 0 00.121.059.188.188 0 00.128-.046l.33-.305a.271.271 0 01.385.018.278.278 0 01.073.197.275.275 0 01-.087.188l-.331.304a.178.178 0 00-.046.191c.009.02.02.04.035.058v.003z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.1"
                    d="M41.558 14.32a.965.965 0 001.028-.895l.03-.417.416.029a.959.959 0 001.026-.895.959.959 0 00-.895-1.025l-.417-.03.029-.416a.959.959 0 00-.895-1.026.965.965 0 00-1.029.895l-.029.417-.417-.029a.965.965 0 00-.793 1.593.95.95 0 00.66.327l.417.03-.029.416a.962.962 0 00.895 1.026h.003zm-.556-1.97l-.69-.046a.415.415 0 01-.387-.446.416.416 0 01.446-.388l.689.046a.27.27 0 00.29-.252l.046-.689a.418.418 0 11.834.058l-.046.69a.272.272 0 00.252.289l.689.046a.415.415 0 01.388.446.415.415 0 01-.446.388l-.69-.046a.272.272 0 00-.289.252l-.046.69a.416.416 0 01-.446.387.415.415 0 01-.388-.446l.046-.689a.273.273 0 00-.067-.197.25.25 0 00-.185-.093z"
                ></path>
                <path
                    fill="#000"
                    d="M41.237 68.288a1.022 1.022 0 00-.116-1.44l-.339-.286.287-.34c.177-.208.264-.471.24-.743a1.014 1.014 0 00-.356-.696 1.012 1.012 0 00-.744-.24c-.272.02-.519.148-.695.356l-.287.34-.339-.29a1.011 1.011 0 00-.744-.24 1.022 1.022 0 00-.58 1.796l.34.285-.287.34a1.023 1.023 0 00.86 1.68c.272-.024.518-.148.695-.357l.287-.339.339.287a1.03 1.03 0 001.132.13c.116-.058.22-.142.31-.246l-.003.003zm-2.03-.768l-.475.56a.459.459 0 01-.304.156.444.444 0 01-.374-.73l.475-.56a.29.29 0 00-.032-.407l-.559-.475a.445.445 0 01.255-.782.452.452 0 01.325.104l.559.475a.292.292 0 00.408-.035l.475-.559a.452.452 0 01.628-.05.452.452 0 01.05.629l-.475.56a.291.291 0 00.035.408l.558.475a.445.445 0 11-.579.674l-.559-.474a.278.278 0 00-.211-.067.29.29 0 00-.197.101l-.003-.003z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.1"
                    d="M32.143 57.965a1.288 1.288 0 001.297 1.272l.56-.006.005.56a1.282 1.282 0 001.298 1.27 1.27 1.27 0 00.903-.385c.24-.246.371-.567.368-.912l-.006-.559.56-.006a1.282 1.282 0 001.27-1.297 1.288 1.288 0 00-1.297-1.272l-.559.006-.006-.559a1.29 1.29 0 00-1.297-1.271 1.27 1.27 0 00-.904.385c-.24.246-.37.568-.367.912l.005.56-.559.005a1.285 1.285 0 00-1.268 1.297h-.003zm2.557-.947l-.009-.92a.557.557 0 01.553-.565.56.56 0 01.566.553l.008.92c0 .2.165.363.368.36l.921-.009a.561.561 0 01.406.95.558.558 0 01-.394.168l-.921.009a.355.355 0 00-.255.11.374.374 0 00-.105.258l.01.92a.557.557 0 01-.95.405.557.557 0 01-.169-.393l-.008-.92a.355.355 0 00-.11-.255.374.374 0 00-.258-.105l-.921.009a.56.56 0 01-.012-1.118l.921-.009c.096 0 .188-.04.255-.11a.35.35 0 00.104-.258z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M39.201 23.526a.83.83 0 00.113-1.167l-.229-.278.278-.229a.831.831 0 00.299-.559.82.82 0 00-.186-.605.82.82 0 00-.559-.298.82.82 0 00-.605.185l-.278.229-.229-.278a.832.832 0 00-.559-.299.83.83 0 00-.718 1.353l.229.278-.278.229a.83.83 0 00.446 1.465.82.82 0 00.605-.185l.278-.229.229.278a.828.828 0 001.167.113l-.003-.003zm-1.512-.9l-.457.379a.363.363 0 01-.264.081.359.359 0 01-.194-.637l.458-.38a.237.237 0 00.032-.33l-.38-.457a.36.36 0 01.313-.588.352.352 0 01.243.13l.38.458a.23.23 0 00.159.084.228.228 0 00.17-.052l.458-.38a.364.364 0 01.264-.081.363.363 0 01.324.394.352.352 0 01-.13.243l-.458.38a.23.23 0 00-.084.159.229.229 0 00.052.17l.38.458a.359.359 0 01-.05.507.363.363 0 01-.263.081.352.352 0 01-.243-.13l-.38-.458a.23.23 0 00-.159-.084.252.252 0 00-.093.01.247.247 0 00-.08.043h.002z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.5"
                    d="M122.945 13.745a.978.978 0 001.356-.263l.237-.35.351.237a.977.977 0 001.095-1.616l-.351-.238.238-.35a.973.973 0 00-.261-1.355.979.979 0 00-1.356.263l-.237.35-.35-.237a.978.978 0 00-1.095 1.619l.35.237-.237.35a.973.973 0 00.263 1.356l-.003-.003zm.513-2.01l-.579-.39a.43.43 0 01-.116-.59.42.42 0 01.588-.114l.579.39a.275.275 0 00.382-.075l.391-.579a.422.422 0 01.588-.113.429.429 0 01.116.59l-.391.58a.273.273 0 00.073.382l.579.391c.092.064.156.16.179.273.021.11 0 .223-.066.318a.43.43 0 01-.591.116l-.579-.39a.29.29 0 00-.206-.044.278.278 0 00-.177.116l-.391.579a.42.42 0 01-.588.113.429.429 0 01-.115-.59l.391-.58a.289.289 0 00.043-.206.251.251 0 00-.041-.101.303.303 0 00-.075-.076h.006zM101.045 26.607a.633.633 0 00-.869.223l-.142.237-.237-.142a.634.634 0 00-.649 1.09l.238.141-.142.238a.634.634 0 101.089.646l.142-.238.237.142a.633.633 0 00.939-.704.626.626 0 00-.29-.388l-.238-.142.142-.237a.635.635 0 00-.223-.87l.003.004zm-.258 1.323l.391.232a.276.276 0 01.099.377.277.277 0 01-.379.095l-.391-.232a.182.182 0 00-.247.064l-.231.391a.277.277 0 01-.478-.28l.231-.392a.186.186 0 00.02-.136.175.175 0 00-.08-.11l-.391-.232a.276.276 0 01.278-.475l.39.232c.041.023.09.032.137.02a.175.175 0 00.11-.08l.232-.392a.277.277 0 01.477.281l-.231.391a.19.19 0 00-.021.136.203.203 0 00.032.064.21.21 0 00.052.046z"
                ></path>
                <path
                    fill="#95C3EA"
                    d="M116.788 14.261a1.202 1.202 0 00-1.251-1.15l-.521.023-.023-.52a1.196 1.196 0 00-.388-.835 1.194 1.194 0 00-.863-.316 1.19 1.19 0 00-.834.389 1.188 1.188 0 00-.316.863l.023.52-.521.024a1.19 1.19 0 00-.834.388 1.203 1.203 0 00.935 2.013l.521-.023.024.521a1.202 1.202 0 102.401-.104l-.024-.521.522-.024a1.203 1.203 0 001.149-1.25v.002zm-2.366.965l.038.863a.533.533 0 01-.136.376.524.524 0 01-.907-.333l-.037-.863a.338.338 0 00-.354-.324l-.863.037a.523.523 0 01-.046-1.045l.863-.038a.334.334 0 00.235-.11.346.346 0 00.089-.243l-.037-.863a.529.529 0 01.136-.377.512.512 0 01.362-.168.52.52 0 01.544.498l.038.864c.003.09.043.173.11.234.067.06.154.093.243.09l.863-.038a.524.524 0 01.047 1.046l-.863.037a.334.334 0 00-.235.11.321.321 0 00-.069.113.314.314 0 00-.021.13v.004zM119.986 33.802c.423-.446.4-1.152-.046-1.575l-.354-.333.333-.354a1.112 1.112 0 00-.046-1.576 1.104 1.104 0 00-.796-.303 1.106 1.106 0 00-.779.35l-.334.353-.353-.333a1.112 1.112 0 00-1.575.047c-.42.446-.4 1.152.046 1.575l.353.333-.333.354a1.115 1.115 0 001.622 1.529l.333-.354.353.334a1.115 1.115 0 001.576-.047zm-2.169-.947l-.548.582a.486.486 0 01-.706-.669l.547-.582a.316.316 0 00-.014-.446l-.583-.547a.488.488 0 01-.02-.687.487.487 0 01.687-.017l.582.547a.325.325 0 00.226.087.305.305 0 00.22-.098l.547-.582a.485.485 0 01.704.668l-.547.583a.324.324 0 00-.087.226c0 .084.037.162.098.22l.582.547c.194.183.203.49.021.687a.486.486 0 01-.687.017l-.582-.547a.325.325 0 00-.226-.087.293.293 0 00-.122.029.344.344 0 00-.098.07h.006z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.2"
                    d="M127.305 42.436a1.13 1.13 0 00-1.529.455l-.235.431-.431-.235a1.11 1.11 0 00-.858-.09 1.112 1.112 0 00-.669.545c-.145.264-.176.57-.089.858.086.29.278.527.544.672l.432.234-.235.432c-.145.263-.177.57-.09.857a1.13 1.13 0 002.074.217l.234-.431.432.234a1.13 1.13 0 001.075-1.984l-.432-.234.235-.432a1.127 1.127 0 00-.455-1.53h-.003zm-.365 2.369l.713.385a.491.491 0 11-.47.863l-.712-.385a.32.32 0 00-.432.127l-.385.713a.491.491 0 01-.863-.47l.385-.712a.323.323 0 00.026-.243.31.31 0 00-.153-.188l-.713-.385a.485.485 0 01-.237-.293.484.484 0 01.04-.374.488.488 0 01.667-.197l.712.386c.075.04.162.049.243.026a.305.305 0 00.189-.154l.385-.712a.491.491 0 01.863.469l-.385.712a.318.318 0 00.127.432z"
                ></path>
                <path
                    fill="#282828"
                    fillOpacity="0.5"
                    d="M23.651 38.264a1.068 1.068 0 00-.93-1.19l-.46-.058.058-.46a1.07 1.07 0 00-.93-1.19 1.069 1.069 0 00-1.19.929l-.058.46-.46-.058a1.07 1.07 0 00-1.19.93 1.068 1.068 0 00.929 1.19l.46.059-.057.46a1.068 1.068 0 002.12.26l.058-.46.46.058a1.068 1.068 0 001.19-.93zm-2.212.502l-.093.761a.468.468 0 01-.174.31.47.47 0 01-.341.096.468.468 0 01-.406-.519l.093-.761a.303.303 0 00-.264-.337l-.762-.092a.468.468 0 01-.231-.828.47.47 0 01.341-.096l.762.093a.31.31 0 00.223-.061.294.294 0 00.113-.2l.093-.762a.474.474 0 01.174-.31.468.468 0 01.652.078.47.47 0 01.095.342l-.093.762a.31.31 0 00.061.223c.05.064.122.104.2.113l.762.093a.468.468 0 01.231.828.47.47 0 01-.341.096l-.762-.093a.31.31 0 00-.301.15.31.31 0 00-.038.11l.006.004z"
                ></path>
            </g>
        </svg>
    );
}

export default Notebook;
