import React, { ReactElement } from "react";
// import Image from "next/image";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import { useTranslation } from "@hooks/useTranslation";
import ScrollToTop from "@components/shared/scrollToTop";
import Notebook from "../icons/Notebook";
import Handshake from "../icons/Handshake";
import Bullseye from "../icons/Bullseye";

export default function WhatWeDo(): ReactElement {
    const { translate } = useTranslation();
    const points = [
        {
            icon: <Notebook />,
            description: translate("WHAT_WE_DO_POINT_1"),
        },
        {
            icon: <Handshake />,
            description: translate("WHAT_WE_DO_POINT_2"),
        },
        {
            icon: <Bullseye />,
            description: translate("WHAT_WE_DO_POINT_3"),
        },
    ];

    return (
        <section
            className="max-w-7xl mx-auto"
            style={{
                counterReset: "section",
            }}
        >
            <FeatureList
                featureList={points}
                mainTitle={translate("WHAT_WE_DO_MAIN_TITLE")}
                classes={{
                    mainTitleClasses: ` font-bold text-2xl text-center   lg:text-3xl  `,
                    innerSectionClasses:
                        "flex flex-col lg:flex-row justify-between  gap-6 mt-[34px]",
                    oneBoxClasses: `text-center  px-6  pt-12  ${styles.item} w-[80%] mx-auto lg:mx-0 lg:w-[380px] min-h-[378px] `,
                    iconClasses:
                        "pb-4 flex justify-center mx-auto border-b-[5px]  border-[#95c3ea] block w-[fit-content]",

                    descriptionClasses: "text-base lg:text-lg leading-8  ",
                    mainSectionClasses: "mx-auto",
                }}
                colors={{
                    mainTitleColor: "#172639",
                }}
            />
            <div className=" text-center sm:mt-14 mt-8">
                <ScrollToTop
                    colors={{
                        bg: "#d21900",
                        text: "white",
                    }}
                    className="rounded"
                >
                    {translate("GET_CASE_REVIEW")}
                </ScrollToTop>
            </div>
        </section>
    );
}
